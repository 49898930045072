import * as React from "react";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {getLocalizedString} from "../../../util/Localization";
type PrivacyCAProps = {
    backButtonPress: () => void,
    isEnglish: boolean
}
export const PrivacyCA:React.ComponentType<PrivacyCAProps> = ({backButtonPress, isEnglish}) => {
    return (
        <div>
            <div className={'privacyterms__title'}>
                <div className={'o-heading--l u-font-weight--700'}>
                    {getLocalizedString('privacy.header.ca','ARC Client Portal Privacy Policy - Canada')}
                </div>
                <ARCButton
                    fill={'filled'}
                    variant={'primary'}
                    size={'md'}
                    id="privacy-back-top"
                    onClick={backButtonPress}
                    label={getLocalizedString('back', 'Back')}
                >
                </ARCButton>
            </div>
            {isEnglish ?
            <div className={'l-container--md o-rich-text'}>
                <p>
                    <span className={'o-body-text'}>Last Modified: March 15, 2024</span>
                </p>
                <p>
                    <span className={'o-body-text'}>
                        This Privacy Policy describes the types of personal information ChargeitSpot
                        LLC (“ChargeItSpot” or “ARC”) and its corporate affiliates (“we”, “us”, “our”)
                        collects about users (“User”, “you”, or “your”) on the ARC client portal (the
                        “Website”), how we use the information, and with whom we may share it.
                    </span>
                </p>
                <p>
                    <span className={'o-body-text'}>
                        By accessing or using the Website, you agree to the terms of this Privacy Policy.
                        Please note that ARC only collects what you, your employer or another entity that
                        you have a business relation with, voluntarily provides to us.
                    </span>
                </p>
                <ol>
                    <li>
                        <span className={'o-body-text'}>PERSONAL INFORMATION WE COLLECT</span>
                        <ol type={'a'}>
                            <li>
                                <span className={'o-body-text'}>
                                    Customer Data – When you interact with us via our Website, we may collect
                                    personal information. ARC may record and use information generated by the use
                                    of ARC kiosk (“Data”), including first and last names, employee identification
                                    numbers, device serial numbers, check in/out information, email addresses,
                                    device issue reporting data, number of users per day, user photographs (used for
                                    security purposes), and other applicable data. We collect this information when
                                    you submit web forms, interact with our website, you use ARC kiosks, or we
                                    provide a service.
                                </span>
                            </li>
                            <li>
                                <span className={'o-body-text'}>Usage Information – We may also collect usage information on how you interact with our Website. This may include cookies and similar technologies. This Usage Information may include:</span>
                            </li>
                            <ul style={{listStyleType: 'circle'}}>
                                <li><span className={'o-body-text'}>Your device serial number or other unique identifier (“Device Identifier”). A Device Identifier is a number that is automatically assigned to your Device used to access this Website, and our computers identify your Device by its Device Identifier;</span>
                                </li>
                                <li><span className={'o-body-text'}>Details of your visits to our Website, including pages visited, traffic data, logs, referral sources, search terms, and other communication data and the resources that you access and use on the Website.</span>
                                </li>
                                <li><span className={'o-body-text'}>The areas within this Website that you visit and your activities there, including remembering you and your preferences;</span>
                                </li>
                                <li><span className={'o-body-text'}>Certain other Device data, including the date and time of day of your visit, among other information.</span>
                                </li>           
                            </ul>
                            <span className={'o-body-text'}>
                                When you access this Website by or through a mobile device, We may collect certain information
                                automatically, including, but not limited to, the type of mobile device you use, your mobile device’s unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
                            </span>
                        </ol>
                    </li>
                    <li>
                        <span className={'o-body-text'}>HOW WE USE PERSONAL INFORMATION</span>
                        <p><span className={'o-body-text'}>We use your personal information to provide our products or services to you or a business that you are associated with. In compliance with regulatory practices and confidentiality agreements, we may use your personal information to:</span></p>
                        <ul>
                            <li><span className={'o-body-text'}>Improve, develop, maintain and update our products and services;</span></li>
                            <li><span className={'o-body-text'}>Secure and protect our products, services, Website and business;</span></li>
                            <li><span className={'o-body-text'}>For data analysis, identifying usage trends and aggregating or de-identifying information for analytics and reporting purposes;</span>
                            </li>
                            <li><span className={'o-body-text'}>Manage and develop business operations; </span></li>
                            <li><span className={'o-body-text'}>For any other purpose with your consent, or</span></li>
                            <li><span className={'o-body-text'}>Meet our legal and regulatory requirements.</span></li>
                        </ul>
                        <span className={'o-body-text'}>We (or our service providers) may aggregate or de-identify personal information and use it for the purposes identified or for servicing ARC.</span>
                    </li>
                    <li>
                        <span className={'o-body-text'}>HOW WE SHARE PERSONAL INFORMATION</span>
                        <ol type={'a'}>
                            <li>
                                <span className={'o-body-text'}>Service Providers – We may share personal information with third-party
                                    service providers to support our websites, products and services. For
                                    example, this may include business analytics vendors, IT and cloud computing
                                    services, maintenance providers.
                                </span>
                            </li>
                            <li>
                                <span className={'o-body-text'}>
                                    Business Transactions – We may disclose your information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us is among the assets transferred.
                                </span>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span className={'o-body-text'}>DATA SECURITY</span>
                        <p>
                            <span className={'o-body-text'}>
                                ARC shall maintain reasonable administrative, physical, and technical safeguards, consistent with generally accepted practices in ARC’s industry, designed to protect personal information to the extent it includes Customer Confidential Information or personally identifiable information of Customer’s users (collectively, “Customer Data”). While we strive to use commercially acceptable means to protect personal information, we cannot guarantee its absolute security. Unfortunately, no method of transmitting information over the internet or storing it electronically is 100% secure. Neither we nor anyone else can—and we do not—guarantee the confidentiality, security, integrity, or availability of any personal information transmitted to or from us over the Internet and/or stored electronically. In all situations, you should carefully consider whether and how to provide personal information to someone else (including us). All transmission of personal information is at your own risk.
                            </span>
                        </p>
                        <p>
                            <span className={'o-body-text'}>
                                ARC ensures that all such safeguards comply with data protection and privacy laws and regulations that are applicable in ARC’s geographic territory to its activities. ARC will notify users through their affiliated ARC Customer of any breach of Customer Data within 72 hours, or as soon as reasonably practicable, after ARC’s confirmation of the breach. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.                                 
                            </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>DATA RETENTION</span>
                        <p>
                            <span className={'o-body-text'}>
                                ARC will retain Customer Data in accordance with a Customer's instructions
                                (including to perform any applicable terms in the Customer Agreement), as long
                                as the business has an active contract with ARC, and as required by applicable
                                law.
                            </span>
                        </p>
                        <p>
                            <span>
                                In the case of photographs taken at the kiosk, they are stored for 30 days, unless
                                required to investigate security incidents such as theft or vandalism.
                            </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>AGE LIMITATIONS</span>
                        <p><span className={'o-body-text'}>The Website is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe we have collected information from a child, please contact us at </span><span
                        ><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span><span
                        >, and we will take appropriate steps to remove the information.</span></p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>CHANGES TO THIS PRIVACY POLICY</span>
                        <p>
                            <span className={'o-body-text'}>
                                ARC may update this statement from time to time, which will be effective as soon as it is posted. The most current version will always be posted on this Website. By continuing to use this Website after we make changes, you will be deemed to have agreed to the changes.
                            </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>ACCURACY AND CORRECTION</span>
                        <p>
                            <span className={'o-body-text'}>
                                You may update and edit your name and email address by requesting your employer
                                make such changes (and as we receive this information from them, we will update it
                                accordingly). You also have the right to request access to your personal information,
                                and may do that through your employer as well.
                            </span>
                        </p>
                        <p>
                            <span className={'o-body-text'}>Please contact the Data Privacy Officer with any questions or concerns at </span>
                            <span className={'o-body-text'}>
                                <a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a>
                            </span>
                            <span className={'o-body-text'}>. </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>DATA PROTECTION OFFICER</span>
                        <p><span className={'o-body-text'}>If you have any questions about this Privacy Policy or our privacy practices please contact our Data Protection Officer at </span><span
                                ><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span><span
                        >.</span></p>
                    </li>
                </ol>
                <p><span className={'o-body-text'}>By using this Website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.</span>
                </p>
            </div>
            :
            <div className={'l-container--md o-rich-text'}>
                <p>
                    <span className={'o-body-text'}>Dernière modification : 15 mars 2023</span>
                </p>
                <p>
                    <span className={'o-body-text'}>
                        La présente politique de confidentialité décrit les types de renseignements personnels que ChargeitSpot LLC (« ChargeItSpot » ou « ARC ») et les membres de son groupe (« nous », « notre », « nos ») recueillent sur les utilisateurs (l’« Utilisateur », « vous », ou « votre ») sur le portail client d’ARC (le « site Web »), comment nous utilisons les renseignements et avec qui nous pouvons les partager.
                    </span>
                </p>
                <p>
                    <span className={'o-body-text'}>
                        En accédant au site Web ou en l’utilisant, vous acceptez les conditions de la présente politique de confidentialité. Veuillez noter qu’ARC ne recueille que les renseignements que vous, votre employeur ou une autre entité avec laquelle vous êtes en relation d’affaires nous fournissez volontairement.
                    </span>
                </p>
                <ol>
                    <li>
                        <span className={'o-body-text'}>LES RENSEIGNEMENTS PERSONNELS QUE NOUS RECUEILLONS</span>
                        <ol type={'a'}>
                            <li>
                                <span className={'o-body-text'}>
                                    Données des clients – Lorsque vous interagissez avec nous au moyen de notre site Web, nous pouvons recueillir des renseignements personnels. ARC peut enregistrer et utiliser des renseignements générés par l’utilisation du guichet ARC (les « données »), y compris les noms et prénoms, les numéros d’identification des employés, les numéros de série des appareils, les informations d’entrée et de sortie, les adresses courriel, les données de signalement des problèmes liés aux appareils, le nombre d’utilisateurs par jour, les photographies des utilisateurs (utilisées à des fins de sécurité) et d’autres données applicables. Nous recueillons ces renseignements lorsque vous soumettez des formulaires Web, interagissez avec notre site Web ou utilisez les guichets d’ARC ou lorsque nous vous fournissons un service.
                                </span>
                            </li>
                            <li>
                                <span className={'o-body-text'}>Renseignements sur l’utilisation – Nous pouvons également recueillir des renseignements sur la façon dont vous interagissez avec notre site Web. Il peut s’agir de témoins et de technologies similaires. Ces renseignements sur l’utilisation peuvent comprendre les éléments suivants :</span>
                            </li>
                            <ul style={{listStyleType: 'circle'}}>
                                <li><span className={'o-body-text'}>le numéro de série ou tout autre identifiant unique de votre appareil (l’« identifiant de l’appareil »). L’identifiant de l’appareil est un numéro qui est attribué automatiquement à l’appareil que vous utilisez pour accéder à ce site Web et que nos ordinateurs utilisent pour identifier votre appareil;</span>
                                </li>
                                <li><span className={'o-body-text'}>les détails de vos visites sur notre site Web, y compris les pages visitées, les données sur le trafic, les journaux, les sources de référence, les termes de recherche et d’autres données de communication, ainsi que les ressources auxquelles vous accédez et que vous utilisez sur le site Web;</span>
                                </li>
                                <li><span className={'o-body-text'}>les sections du site Internet que vous visitez et les activités que vous y menez, y compris le fait de se souvenir de vous et de vos préférences;</span>
                                </li>
                                <li><span className={'o-body-text'}>certaines autres données, notamment la date et l’heure de votre visite, entre autres renseignements.</span>
                                </li>           
                            </ul>
                            <span className={'o-body-text'}>
                                Lorsque vous accédez à ce site Web au moyen ou par l’intermédiaire d’un appareil mobile, nous pouvons recueillir certains renseignements automatiquement, notamment le type d’appareil mobile que vous utilisez, son identifiant unique et son adresse IP, votre système d’exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l’appareil et d’autres données de diagnostic.
                            </span>
                        </ol>
                    </li>
                    <li>
                        <span className={'o-body-text'}>COMMENT NOUS UTILISONS LES RENSEIGNEMENTS PERSONNELS</span>
                        <p><span className={'o-body-text'}>Nous utilisons vos renseignements personnels pour vous fournir nos produits ou nos services ou les fournir à une entreprise à laquelle vous êtes associé. Conformément aux pratiques prescrites par règlement et aux ententes de confidentialité, nous pouvons utiliser vos renseignements personnels aux fins suivantes :</span></p>
                        <ul>
                            <li><span className={'o-body-text'}>améliorer, développer, maintenir et mettre à jour nos produits et nos services;</span></li>
                            <li><span className={'o-body-text'}>sécuriser et protéger nos produits, nos services, notre site Web et notre entreprise;</span></li>
                            <li><span className={'o-body-text'}>analyser les données, cerner les tendances d’utilisation et regrouper ou dépersonnaliser les renseignements à des fins d’analyse et de présentation de l’information;</span>
                            </li>
                            <li><span className={'o-body-text'}>gérer et développer nos activités commerciales;</span></li>
                            <li><span className={'o-body-text'}>à toute autre fin raisonnable, avec votre consentement, ou</span></li>
                            <li><span className={'o-body-text'}>satisfaire aux exigences que nous imposent les lois et les règlements applicables.</span></li>
                        </ul>
                        <span className={'o-body-text'}>Nous (ou nos prestataires de services) pouvons regrouper ou dépersonnaliser les renseignements personnels et les utiliser aux fins indiquées ou aux fins de l’entretien du guichet ARC.</span>
                    </li>
                    <li>
                        <span className={'o-body-text'}>COMMENT NOUS PARTAGEONS LES RENSEIGNEMENTS PERSONNELS</span>
                        <ol type={'a'}>
                            <li>
                                <span className={'o-body-text'}>
                                    Prestataires de services – Nous pouvons partager des renseignements personnels avec des prestataires de services tiers à l’appui de nos sites Web, de nos produits et de nos services.  Il peut s’agir, par exemple, de fournisseurs d’outils d’analyse commerciale, de services informatiques et d’infonuagique ou de fournisseurs de services de maintenance.
                                </span>
                            </li>
                            <li>
                                <span className={'o-body-text'}>
                                    Transactions commerciales – Nous pouvons divulguer vos renseignements à un acheteur ou à un autre successeur dans le cas d’une fusion, d’un dessaisissement, d’une restructuration, d’une réorganisation, d’une dissolution ou de toute autre forme de vente ou de transfert d’une partie ou de la totalité de notre actif, que ce soit en tant qu’entreprise en activité ou dans le cadre d’une faillite, d’une liquidation ou d’une procédure similaire, dans le cadre de laquelle les renseignements personnels que nous détenons font partie de l’actif transféré.
                                </span>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span className={'o-body-text'}>SÉCURITÉ DES DONNÉES</span>
                        <p>
                            <span className={'o-body-text'}>
                                ARC doit maintenir des mesures de protection administratives, physiques et techniques raisonnables, conformes aux pratiques généralement reconnues dans son secteur d’activité, conçues pour protéger les renseignements personnels dans la mesure où ils comprennent des renseignements confidentiels sur le client ou des renseignements permettant d’identifier les utilisateurs du client (collectivement, les « données du client »). Bien que nous nous efforcions d’utiliser des moyens commercialement acceptables pour protéger les renseignements personnels, nous ne pouvons pas garantir leur sécurité absolue. Malheureusement, aucune méthode de transmission de renseignements par Internet ou de stockage électronique n’est entièrement sûre. Nous ne pouvons garantir, et ne garantissons pas, la confidentialité, la sécurité, l’intégrité ou la disponibilité des renseignements personnels qui nous sont transmis ou que nous transmettons par Internet et/ou qui sont stockés électroniquement, et personne d’autre ne peut donner une telle garantie. Dans toutes les situations, vous devez réfléchir soigneusement à l’opportunité et à la manière de fournir des renseignements personnels à quiconque (y compris à nous). Vous transmettez vos renseignements personnels à vos risques et périls.
                            </span>
                        </p>
                        <p>
                            <span className={'o-body-text'}>
                                ARC veille à ce que toutes ces mesures de protection soient conformes aux lois et aux règlements en matière de protection des données et des renseignements personnels qui s’appliquent à ses activités sur le territoire où elle les exerce. ARC informera les utilisateurs, par l’intermédiaire de leur client ARC affilié, de toute atteinte aux données du client dans les 72 heures, ou dès que cela est raisonnablement possible, après avoir confirmé l’atteinte. Toutefois, aucune méthode de transmission par Internet ou de stockage électronique n’est entièrement sûre, et nous ne pouvons pas garantir une sécurité absolue.
                            </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>CONSERVATION DES DONNÉES</span>
                        <p>
                            <span className={'o-body-text'}>
                                ARC conserve les données des clients conformément aux instructions de ces derniers (y compris pour exécuter toute modalité applicable de l’entente conclue avec le client), tant que l’entreprise a un contrat en cours avec ARC, et selon les exigences des lois applicables.
                            </span>
                        </p>
                        <p>
                            <span>
                                En ce qui concerne les photographies prises au guichet, elles sont conservées pendant 30 jours, sauf si d’autres dispositions sont nécessaires en cas d’enquête sur des incidents de sécurité, tels que le vol ou le vandalisme.
                            </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>LIMITES D’ÂGE</span>
                        <p>
                            <span className={'o-body-text'}>Le site Web n’est pas destiné aux personnes âgées de moins de 18 ans. Nous ne recueillons pas sciemment de renseignements personnels auprès d’enfants de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous pensez que nous avons recueilli des renseignements auprès d’un enfant, veuillez nous contacter à l’adresse <span><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span> et nous prendrons les mesures appropriées pour supprimer ces renseignements.</span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>MODIFICATIONS DE LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ</span>
                        <p>
                            <span className={'o-body-text'}>
                                ARC peut mettre la présente déclaration à jour de temps à autre et la version modifiée prend effet dès sa publication. La version la plus récente sera toujours affichée sur ce site Web. Si vous continuez à utiliser ce site Web une fois que de telles modifications ont été faites, vous serez réputé avoir accepté les modifications en question.
                            </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>EXACTITUDE ET CORRECTION</span>
                        <p>
                            <span className={'o-body-text'}>
                                Vous pouvez mettre à jour et modifier votre nom et votre adresse courriel en demandant à votre employeur de faire ces corrections (et au fur et à mesure que nous recevrons ces renseignements de sa part, nous les mettrons à jour en conséquence). Vous avez également le droit de demander l’accès à vos renseignements personnels et vous pouvez ainsi le faire par l’intermédiaire de votre employeur.
                            </span>
                        </p>
                        <p>
                            <span className={'o-body-text'}>Si vous avez des questions ou des inquiétudes, veuillez contacter le responsable de la protection des données à l’adresse </span>
                            <span className={'o-body-text'}>
                                <a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a>
                            </span>
                            <span className={'o-body-text'}>. </span>
                        </p>
                    </li>
                    <li>
                        <span className={'o-body-text'}>RESPONSABLE DE LA PROTECTION DES DONNÉES</span>
                        <p><span className={'o-body-text'}>Si vous avez des questions concernant la présente politique ou nos pratiques en matière de protection des renseignements personnels, veuillez contacter notre responsable de la protection des données à l’adresse </span><span><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span><span>.</span>
                        </p>
                    </li>
                </ol>
                <p><span className={'o-body-text'}>En utilisant ce site Web, vous reconnaissez que vous avez lu la présente politique de confidentialité et que vous en avez compris les modalités et en convenez.</span>
                </p>
            </div>
            }
            <div className={'privacyterms-back-button-div'}>
                <ARCButton
                    fill={'filled'}
                    variant={'primary'}
                    size={'md'}
                    id="privacy-back-bottom"
                    onClick={backButtonPress}
                    label={getLocalizedString('back', 'Back')}
                >
                </ARCButton>
            </div>
        </div>
    )
}