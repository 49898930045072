import * as React from 'react';
import {styled} from "@material-ui/core/styles";
import {
    CircularProgress,
    FormControl,
    useTheme
} from "@material-ui/core";
import {LIGHTER_GREY} from "../../../themes/colors";
import {useAppDispatch, useAppSelector} from "../../../store/hooks" ;
import {
    bulkAddEmployeeLocation,
    bulkReactivateEmployees,
    bulkDeactivateEmployees,
    employeeContextSelector,
    bulkUpdateDevicePermissions
} from "../employeeSlice";
import {
    appContextSelector,
    currentUserLocationOptionsSelector
} from "../../app-context/appContextSlice";
import {DevicePickupPermissionDto} from "../../../dtos/DevicePickupPermission";
import {isMDCDisabledBrand} from "../../../dtos/Brand";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCSelectInput} from "../../ui-components/design-system/ARCSelectInput";

type BulkEmployeeActionProps = {
    bulkEmployeeList: {
        [employeeId: number] : boolean
    },
    devicePickupPermissionList: DevicePickupPermissionDto[],
    resetBulkList: () => void
}


export const BulkEmployeeAction:React.ComponentType<BulkEmployeeActionProps> = ({bulkEmployeeList, resetBulkList,  devicePickupPermissionList}) => {
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const employeeContext = useAppSelector(employeeContextSelector);
    const locationOptions = useAppSelector(currentUserLocationOptionsSelector);
    const theme = useTheme();

    const BULK_ACTION_REACTIVATE = getLocalizedString('bulkTask.reactivate', 'Reactivate Users');
    const BULK_ACTION_DEACTIVATE = getLocalizedString('bulkTask.deactivate', 'Deactivate Users');
    const BULK_ACTION_ADD_LOCATION = getLocalizedString('bulkTask.addLocation', 'Add Location');
    const BULK_ACTION_UPDATE_DEVICE_PERMISSION = getLocalizedString('bulkTask.updateDevicePickupPermission', 'Change Device Permissions Group');

    type BulkAction = typeof BULK_ACTION_REACTIVATE | typeof BULK_ACTION_DEACTIVATE | typeof BULK_ACTION_ADD_LOCATION | typeof BULK_ACTION_UPDATE_DEVICE_PERMISSION | undefined;

    const [selectedAction, setSelectedAction] = React.useState<BulkAction | undefined>(undefined);
    const [selectedLocation, setSelectedLocation] = React.useState<number | undefined>(undefined);
    const [selectedPermission, setSelectedPermission] = React.useState<number | undefined>(undefined);

    const permissionList = React.useMemo<DevicePickupPermissionDto[]>(() => {
        const list = devicePickupPermissionList.slice();

        return list.sort((p1, p2) => {
                if (p1.default && !p2.default) {
                    return -1;
                } else if (!p1.default && p2.default) {
                    return 1;
                }

                return p1.permissionName.localeCompare(p2.permissionName);
            }
        )
    }, [devicePickupPermissionList]);

    const hasLocations = React.useMemo(() => {
        return locationOptions?.length > 0;
    }, [locationOptions?.length]);

    const allowDevicePickupPermissionEdit = React.useMemo(() => {
        return !isMDCDisabledBrand(context.currentBrand?.brandId);
    }, [context.currentBrand?.brandId]);

    const hasDevicePermissions = React.useMemo(() => {
        return permissionList.length > 0;
    }, [permissionList.length]);

    const showLocationDropDown = React.useMemo(() => {
        return selectedAction === BULK_ACTION_ADD_LOCATION && hasLocations;
    }, [BULK_ACTION_ADD_LOCATION, hasLocations, selectedAction]);

    const showDevicePermissionDropDown = React.useMemo(() => {
        return selectedAction === BULK_ACTION_UPDATE_DEVICE_PERMISSION && hasDevicePermissions;
    }, [BULK_ACTION_UPDATE_DEVICE_PERMISSION, hasDevicePermissions, selectedAction]);

    const BULK_ACTION_LIST : string[] = React.useMemo(() => {
        let actions = [];

        actions.push(BULK_ACTION_REACTIVATE);
        actions.push(BULK_ACTION_DEACTIVATE);

        if(hasLocations) {
            actions.push(BULK_ACTION_ADD_LOCATION);
        }

        if(hasDevicePermissions && allowDevicePickupPermissionEdit) {
            actions.push(BULK_ACTION_UPDATE_DEVICE_PERMISSION);
        }

        return actions;
    }, [BULK_ACTION_ADD_LOCATION, BULK_ACTION_DEACTIVATE, BULK_ACTION_REACTIVATE, BULK_ACTION_UPDATE_DEVICE_PERMISSION, allowDevicePickupPermissionEdit, hasDevicePermissions, hasLocations]);

    const bulkActionOptions = React.useMemo(() => {
        const options = BULK_ACTION_LIST.map(action => {
            return {
                value: action,
                label: action
            }
        });

        return [{value: undefined, label: getLocalizedString('bulkTask.select', 'Select Bulk Task')}, ...options];
    }, [BULK_ACTION_LIST]);

    const locationOptionsList = React.useMemo(() => {
        const options = locationOptions.map(loc => {
            return {
                value: loc.locationId,
                label: loc.locationName
            }
        });

        return [
            {
                value: undefined,
                label: getLocalizedString('bulkTask.selectLocation', 'Select Location')
            },
            {
                value: -1,
                label: getLocalizedString('bulkTask.allLocations', 'All Locations')
            },
            ...options
        ];
    }, [locationOptions]);

    const permissionOptions = React.useMemo(() => {
        const options = permissionList.map(perm => {
            return {
                value: perm.devicePickupPermissionId,
                label: perm.default ? getLocalizedString('bulkTask.defaultGroup', `Default Group (${perm.permissionName})`, [perm.permissionName]) : perm.permissionName
            }
        })

        return [{value: undefined, label: getLocalizedString('bulkTask.selectDevicePickupPermissionGroup', 'Select Group')}, ...options];
    }, [permissionList]);

    const selectedEmployeeList = React.useMemo(() => {
        return Object.keys(bulkEmployeeList).map(Number);
    }, [bulkEmployeeList]);

    const onSubmit = React.useCallback(async () => {
        if(context.currentLocation?.locationId) {
            switch (selectedAction) {
                case BULK_ACTION_REACTIVATE:
                    await dispatch(bulkReactivateEmployees({
                        locationId: context.currentLocation.locationId,
                        employeeList: selectedEmployeeList
                    })).then(resetBulkList)
                    break
                case BULK_ACTION_DEACTIVATE:
                    await dispatch(bulkDeactivateEmployees({
                        locationId: context.currentLocation.locationId,
                        employeeList: selectedEmployeeList
                    })).then(resetBulkList)
                    break
                case BULK_ACTION_ADD_LOCATION:
                    if(selectedLocation && context.currentBrand) {
                        await dispatch(bulkAddEmployeeLocation({
                            brandId: context.currentBrand.brandId,
                            locationId: context.currentLocation.locationId,
                            selectedLocationId: selectedLocation,
                            employeeList: selectedEmployeeList
                        })).then(resetBulkList)
                    }
                    break
                case BULK_ACTION_UPDATE_DEVICE_PERMISSION:
                    if(selectedPermission) {
                        await dispatch(bulkUpdateDevicePermissions({
                            locationId: context.currentLocation.locationId,
                            devicePickupPermissionId: selectedPermission,
                            employeeList: selectedEmployeeList
                        })).then(resetBulkList)
                    }
            }
        }
    }, [context.currentLocation?.locationId, context.currentBrand, selectedAction, BULK_ACTION_REACTIVATE, dispatch, selectedEmployeeList, resetBulkList, BULK_ACTION_DEACTIVATE, BULK_ACTION_ADD_LOCATION, selectedLocation, BULK_ACTION_UPDATE_DEVICE_PERMISSION, selectedPermission])


    return (
        <ContainerDiv>

            <div className={'section-header'}>
                {getLocalizedString('bulkTask.create', 'Create Bulk Task')}

                {employeeContext.bulkActionLoading &&
                <CircularProgress style={{margin: '0 0 0 15px'}} size={30}/>
                }
            </div>

            <div className='u-display--flex u-display--flex-row u-flex-wrap u-align-items--end u-gap-sm'>
                <Container>
                    <div className={'field-text'} style={{ width: '20vw', maxWidth: '250px', minWidth: '150px', marginRight: '3vw', color: theme.palette.primary.main}}>
                        {getLocalizedString('bulkTask.task', 'Bulk Task')}
                    </div>

                    <ARCSelectInput
                       id={'user-bulk-action-select-action-input'}
                       size={'md'}
                       value={selectedAction}
                       style={{width: '25vw',
                           maxWidth: '300px',
                           minWidth: '200px',
                           height: '40px',
                           margin: '0 22px 0 .5vw',
                           padding: '2px 10px 2px 10px'}}
                       options={bulkActionOptions}
                       onChange={(option) => {
                           const val = option.value as BulkAction;

                           if(val) {
                               setSelectedAction(val);
                           } else {
                               setSelectedAction(undefined);
                           }
                       }}
                   />
                </Container>

                {showLocationDropDown &&
                <Container>
                    {showLocationDropDown &&
                        <div className={'field-text'} style={{color: theme.palette.primary.main}}>{getLocalizedString('bulkTask.addLocation.label', 'Enable Access To')}</div>
                    }

                    <ARCSelectInput
                       id={'user-bulk-action-select-location-input'}
                       size={'md'}
                       style={{width: '25vw',
                           maxWidth: '300px',
                           minWidth: '200px',
                           height: '40px',
                           margin: '0 22px 0 .5vw',
                           padding: '2px 10px 2px 10px'}}
                       value={selectedLocation}
                       className={'field-text'}
                       options={locationOptionsList}
                       onChange={(option) => {
                           const val = option.value as number;

                           if(val) {
                               setSelectedLocation(val);
                           } else {
                               setSelectedLocation(undefined);
                           }
                       }}
                   />
                </Container>
                }

                {showDevicePermissionDropDown &&
                <Container>
                    {showDevicePermissionDropDown &&
                        <div className={'field-text'} style={{color: theme.palette.primary.main}}>{getLocalizedString('bulkTask.newDevicePickupPermissionGroup', 'Change Group to')}</div>
                    }
                    <ARCSelectInput
                       id={'user-bulk-action-select-location-input'}
                       size={'md'}
                       value={selectedPermission}
                       className={'field-text'}
                       style={{width: '25vw',
                           maxWidth: '300px',
                           minWidth: '200px',
                           height: '40px',
                           margin: '0 22px 0 .5vw',
                           padding: '2px 10px 2px 10px'}}
                       options={permissionOptions}
                       onChange={(option) => {
                           const val = option.value as number;

                           if(val) {
                               setSelectedPermission(val);
                           } else {
                               setSelectedPermission(undefined);
                           }
                       }}
                    />
                </Container>
                }

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <ARCButton
                        fill={'filled'}
                        size={'sm'}
                        variant={'primary'} 
                        disabled={!selectedAction || Object.keys(bulkEmployeeList).length === 0} onClick={onSubmit}
                        label={getLocalizedString('bulkTask.submit', 'Submit Bulk Task')}
                    >
                    </ARCButton>
    
                    {!(!selectedAction || Object.keys(bulkEmployeeList).length === 0) &&
                    <ARCButton
                        fill={'outlined'}
                        size={'sm'}
                        variant={'secondary'}
                        label={getLocalizedString('bulkTask.cancel', 'Clear')}
                        onClick={() => {setSelectedAction(undefined)}}
                    >
                    </ARCButton>
                    }
                </div>
            </div>



        </ContainerDiv>
    );
}

const ContainerDiv = styled('div')  ({
    backgroundColor: LIGHTER_GREY,
    margin: '1vh 0 5vh 0',
    padding: '10px 10px 30px 10px',
    borderRadius: '15px',
    boxShadow: '7px 7px 6px #00000029',
    width: '100%',
    "@media (max-width:600px)": {
        display: 'flex',
        flexDirection: 'column'
    }
});

const Container = styled(FormControl) ({
    margin: '0 10px 0 10px'
});
