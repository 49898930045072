import * as React from 'react';
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {
    Button, CircularProgress,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector, locationByIdSelector} from "../../app-context/appContextSlice";
import {
    DEV_STATUS_ACTIVE, DEV_STATUS_INACTIVE,
    DEV_STATUS_PENDING,
    DeviceDto, DeviceValidationKeys, getDeviceIdNumberValidation,
    getStatusDisplay,
    isActive,
    isInactive
} from "../../../dtos/Device";
import {
    deviceContextActions,
    deviceContextSelector,
    getDeviceForEdit,
    saveDevice,
} from "../deviceSlice";
import {AppWithFooter} from "../../../App";
import {
    ErrorMessageSectionItem, FormTitle
} from "../../ui-components/StyledComponents";
import {deviceTypeSelector, getDeviceTypes} from "../deviceTypeSlice";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {useCurrentUserContext} from "../../app-context/CurrentUserContextContainer";
import {calculateResponsiveSizeWithMinMax, isAlphanumeric} from "../../../util/TextUtils";
import {ArrowBack} from "@material-ui/icons";
import "../../../css/EditDevice.css"
import {DEVICE_DASHBOARD} from "../../../dtos/Report";
import ErrorWrapper, {useFieldErrors} from "../../ui-components/ErrorWrapper";
import {DeviceDialog} from "../components/DeviceDialog";
import {MAX_DEVICE_TAG_LENGTH} from "../../../constants/DeviceConstants";
import {Option} from "../../ui-components/ColorSelect";
import { getLocalizedString } from '../../../util/Localization';
import {SAMS_BRANDS, WALMART_BRANDS} from "../../../dtos/Brand";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCBadge} from "../../ui-components/design-system/ARCBadge";
import {ARCSelectInput} from "../../ui-components/design-system/ARCSelectInput";
import {ARCTextInput} from "../../ui-components/design-system/ARCTextInput";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, deviceId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(deviceId) {
                dispatch(getDeviceForEdit({locationId: parseInt(locationId), deviceId: parseInt(deviceId)}));
            }
        }
        else if(deviceId && context.currentLocation?.locationId) {
            dispatch(getDeviceForEdit({locationId: context.currentLocation.locationId, deviceId: parseInt(deviceId)}));
        }

    }, [context.currentLocation?.locationId, dispatch, deviceId, locationId]);
}

export const EditDevice = () => {
    useAutoSelectLocationByUrl();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const currentUserContext = useCurrentUserContext();
    const deviceContext = useAppSelector(deviceContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const location = useAppSelector(locationByIdSelector(deviceContext.selectedDevice?.locationId ?? -1));
    
    const isSamsDisabled = React.useMemo(() => {
        if (SAMS_BRANDS.find(id => id === context.currentBrand?.brandId) || WALMART_BRANDS.find(id => id === context.currentBrand?.brandId)) {
            if (context.currentLocation?.serialIsDevice) {
                return true;
            }
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.serialIsDevice])
    
    const editAtKioskTooltip = React.useMemo(() => {
        if (isSamsDisabled) {
            return getLocalizedString('editDevice.editAtKiosk', 'Please edit the Device ID / Serial Number at the kiosk')
        }
        return '';
    }, [isSamsDisabled])

    const canEdit = React.useMemo(() => {
        return !currentUserContext.groups.isArcTechSupport && !currentUserContext.groups.isSSOHQUser;
    }, [currentUserContext.groups.isArcTechSupport, currentUserContext.groups.isSSOHQUser]);

    const [deviceIdNumberLocked, setDeviceIdNumberLocked] = React.useState<boolean>(true);
    const [deviceTagLocked, setDeviceTagLocked] = React.useState<boolean>(true);
    const [deviceTypeLocked, setDeviceTypeLocked] = React.useState<boolean>(true);

    const stateDevice = deviceContext.selectedDevice;

    const [formDevice, setFormDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_PENDING,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const DeviceTypeOptions = React.useMemo(() => {
        let options : Option[] = deviceTypeContext.deviceTypes.map(deviceType => {
            return {
                value: deviceType.deviceTypeId,
                label: deviceType.deviceTypeName
            }
        });

        return [{value: undefined, label: getLocalizedString('createDevice.selectDeviceType', 'Select Device Type')}, ...options];
    }, [deviceTypeContext.deviceTypes]);
    
    const serialIsDeviceId = React.useMemo(() => {
        if (context.currentLocation?.serialIsDevice) {
            if (deviceTypeContext.deviceTypes.length > 0 && !formDevice.deviceType?.serialIsDevice) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }, [context.currentLocation, deviceTypeContext.deviceTypes, formDevice.deviceType])
    
    const [showSerialIsDeviceDialog, setShowSerialIsDeviceDialog] = React.useState<boolean>(false);

    const {fieldErrors,
        setFieldErrors,
        createErrorProps,
        setFieldError,
        clearFieldError,
        clearAllErrors} = useFieldErrors();

    React.useEffect(() => {
        if(stateDevice) {
            setFormDevice(stateDevice);
        }
    }, [stateDevice]);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetSaveDevice())}
    }, [dispatch]);

    React.useEffect(() => {
        if(context.currentLocation?.locationId) {
            dispatch(getDeviceTypes({locationId: context.currentLocation.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch]);

    // const inputsMatch = () => updateDeviceForm.newDeviceId === updateDeviceForm.confirmNewDeviceId;

    const computedDeviceIdNumber = React.useMemo(() => {
        if(context.currentBrand?.deviceNumberPrefix) {
            if(formDevice.deviceIdNumber?.startsWith(context.currentBrand.deviceNumberPrefix)) {
                return formDevice.deviceIdNumber;
            } else {
                return context.currentBrand.deviceNumberPrefix + formDevice.deviceIdNumber;
            }
        } else {
            return formDevice.deviceIdNumber;
        }
    }, [context.currentBrand?.deviceNumberPrefix, formDevice.deviceIdNumber]);
    
    const computedTagIdNumber = React.useMemo(() => {
        if (context.currentBrand?.deviceNumberPrefix) {
            if (formDevice.deviceTag?.startsWith(context.currentBrand.deviceNumberPrefix)) {
                return formDevice.deviceTag;
            } else {
                return context.currentBrand?.deviceNumberPrefix + formDevice.deviceTag;
            }
        }
        return formDevice.deviceTag;
    }, [context.currentBrand?.deviceNumberPrefix, formDevice.deviceTag])
    
    const brandProvidedValidationOnSave = (input: string, minLength: number, maxLength: number) => {
        if (minLength) {
            if (maxLength && (input?.length < minLength || input?.length > maxLength)) {
                if (minLength === maxLength) {
                    return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${minLength} characters`, [`${minLength}`]);
                } else {
                    return getLocalizedString('createDevice.deviceIdNumber.minAndMax', `Device ID must be between ${minLength} and ${maxLength} characters`, [`${minLength}`, `${maxLength}`]);
                }
            } else if (input?.length < minLength) {
                return getLocalizedString('createDevice.deviceIdNumber.atLeastMin', `Device ID must be at least ${minLength} characters`, [`${minLength}`]);
            }
        }
        return undefined;
    }
    const cisProvidedValidationOnSave = (input: string, length: number | null) => {
        if (length !== null && input?.length !== length) {
            return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${length} characters`, [`${length}`]);
        }
        return undefined;
    }

    const validateDeviceIdNumber = React.useCallback((input: string) => {
        if (input?.length > 0) {
            if (!isAlphanumeric(input)) {
                return getLocalizedString('createDevice.deviceIdNumber.specialCharacters', 'Device ID Number cannot include special characters');
            }
        }
        if (context.currentBrand) {
            const minLength: number = context.currentBrand.deviceNumberLengthMin;
            const maxLength: number | null = context.currentBrand.deviceNumberLengthMax;
            const length: number | null = context.currentBrand.deviceNumberLength;
            const brandProvidedError = brandProvidedValidationOnSave(input, minLength, maxLength);
            const cisProvidedError = cisProvidedValidationOnSave(input, length);
            return getDeviceIdNumberValidation(minLength, length, serialIsDeviceId, brandProvidedError, cisProvidedError)
        }
        return '';
    }, [context.currentBrand, serialIsDeviceId]);
    
    const brandProvidedValidationOnChange = (input: string, minLength: number, maxLength: number) => {
        if (minLength) {
            if (maxLength && (input?.length > maxLength)) {
                if (minLength === maxLength) {
                    return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${minLength} characters`, [`${minLength}`]);
                } else {
                    return getLocalizedString('createDevice.deviceIdNumber.minAndMax', `Device ID must be between ${minLength} and ${maxLength} characters`, [`${minLength}`, `${maxLength}`]);
                }
            }
        }
        return undefined;
    }
    const cisProvidedValidationOnChange = (input: string, length: number | null) => {
        if (length !== null && input?.length > length) {
            return getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${length} characters`, [`${length}`]);
        }
        return undefined;
    }
    
    const lockDeviceIdNumber = React.useCallback((input: string) => {
        if (input?.length > 0) {
            if (!isAlphanumeric(input)) {
                return getLocalizedString('createDevice.deviceIdNumber.specialCharacters', 'Device ID Number cannot include special characters');
            }
            if (context.currentBrand) {
                const minLength: number = context.currentBrand.deviceNumberLengthMin;
                const maxLength: number | null = context.currentBrand.deviceNumberLengthMax;
                const length: number | null = context.currentBrand.deviceNumberLength;
                const brandProvidedError = brandProvidedValidationOnChange(input, minLength, maxLength);
                const cisProvidedError = cisProvidedValidationOnChange(input, length);
                return getDeviceIdNumberValidation(minLength, length, serialIsDeviceId, brandProvidedError, cisProvidedError)
            }
        }
        return '';
    }, [context.currentBrand, serialIsDeviceId]);

    const validateDeviceTag = React.useCallback((deviceTag: string) => {
        if (deviceTag?.length > 0) {
            if (!isAlphanumeric(deviceTag)) {
                return getLocalizedString('createDevice.serialNumber.specialCharacters', 'Serial Number cannot include special characters');
            }
        }
        if (deviceTag?.length > MAX_DEVICE_TAG_LENGTH) {
            return getLocalizedString('createDevice.serialNumber.max', 'Serial Number cannot exceed 25 characters');
        }

        return '';
    }, []);

    const validateDevice = React.useCallback(() => {
        let hasErrors = false;

        let newFieldErrors: typeof fieldErrors = {};

        if(formDevice.deviceTag) {
            const deviceTagError = validateDeviceTag(formDevice.deviceTag);
            if (!!deviceTagError) {
                hasErrors = true;
                newFieldErrors = ({
                    ...newFieldErrors, [`${DeviceValidationKeys.deviceTag}`]: {
                        error: deviceTagError,
                        shown: true
                    }
                });
            }
        }


        if(formDevice.deviceIdNumber) {
            const deviceNumberError = validateDeviceIdNumber(computedDeviceIdNumber);
            if (!!deviceNumberError) {
                hasErrors = true;
                newFieldErrors = ({...newFieldErrors, [`${DeviceValidationKeys.deviceIdNumber}`]: {
                    error: deviceNumberError,
                    shown: true
                }});
            }
        } else {
            if(!formDevice.deviceId) {
                hasErrors = true;
                newFieldErrors = ({...newFieldErrors, [`${DeviceValidationKeys.deviceIdNumber}`]: {
                    error: getLocalizedString('required', 'Please fill out this field'),
                    shown: true
                }});
            }
        }

        if(hasErrors) {
            setFieldErrors(newFieldErrors);
        }

        return !hasErrors;
    }, [formDevice.deviceTag, setFieldErrors, formDevice.deviceIdNumber, formDevice.deviceId, validateDeviceIdNumber, computedDeviceIdNumber, validateDeviceTag]);

    const submitForm = React.useCallback((deviceDto: DeviceDto, isDeviceId: boolean, isDeviceTag: boolean, isDeviceType: boolean) => {
        if(!validateDevice()) {
            return;
        }
        clearAllErrors();
        if (context.currentLocation?.locationId && deviceDto.deviceId) {
            let deviceFieldToSave = {
                isDeviceId: isDeviceId,
                isDeviceTag: isDeviceTag,
                isDeviceType: isDeviceType
            }
            dispatch(
                saveDevice({
                        deviceDto: {...deviceDto},
                        locationId: context.currentLocation.locationId,
                        deviceFieldToSave
                    }
                )
            );
        }
    }, [context.currentLocation?.locationId, dispatch, validateDevice, clearAllErrors]);
    
    const getStatusTooltip = React.useCallback((device: DeviceDto) => {
        switch (device.status) {
            case DEV_STATUS_ACTIVE:
                return getLocalizedString('createDevice.status.active', 'This device can be used at an ARC kiosk.');
            case DEV_STATUS_PENDING:
                return getLocalizedString('createDevice.status.pending', 'This device needs to be activated at an ARC kiosk.');
            case DEV_STATUS_INACTIVE:
                return getLocalizedString('createDevice.status.inactive', 'Reactivate this device to use it at an ARC kiosk.');
            default:
                return undefined;
        }
    }, []);
    
    const deviceLocationName = React.useMemo(() => {
        if (deviceContext.selectedDevice?.locationId) {
           return location?.locationName;
        }
    },[deviceContext.selectedDevice?.locationId, location])
    
    const deviceStatusVariant = React.useMemo(() => {
        if (deviceContext.selectedDevice) {
            return isActive(deviceContext.selectedDevice) ? 'success' : isInactive(deviceContext.selectedDevice) ? 'disabled' : 'default';
        }
    }, [deviceContext.selectedDevice])
    
    React.useEffect(() => {
        if (deviceContext.saveSuccessful) {
            setDeviceIdNumberLocked(true);
            setDeviceTagLocked(true);
            setDeviceTypeLocked(true);
        }
    }, [deviceContext.saveSuccessful]);
    
    const showDeviceTagEdit = React.useMemo(() => {
        if (deviceTypeLocked && deviceIdNumberLocked) {
            return true;
        }
    }, [deviceTypeLocked, deviceIdNumberLocked]);
    
    const showDeviceTypeEdit = React.useMemo(() => {
        if (deviceTagLocked && deviceIdNumberLocked) {
            return true;
        }
    }, [deviceTagLocked, deviceIdNumberLocked]);
    
    const showDeviceIdEdit = React.useMemo(() => {
        if (deviceTagLocked && deviceTypeLocked) {
            return true;
        }
    }, [deviceTagLocked, deviceTypeLocked]);
    
    const deviceIdSaveDisabled = React.useMemo(() => {
        let deviceIdNumber = formDevice.deviceIdNumber;
        let prefix = context.currentBrand?.deviceNumberPrefix; 
        if (prefix && deviceIdNumber === prefix) {
            return true; 
        } else {
            return formDevice.deviceIdNumber === '';
        }
    }, [formDevice.deviceIdNumber, context.currentBrand?.deviceNumberPrefix])
    
    const deviceTagSaveDisabled = React.useMemo(() => {
        return formDevice.deviceTag === '';
    }, [formDevice.deviceTag])
    
    const deviceIdSaved = React.useMemo(() => {
        return deviceContext.saveSuccessful && (deviceContext.deviceFieldToSave?.isDeviceId ?? false)
    }, [deviceContext.saveSuccessful, deviceContext.deviceFieldToSave?.isDeviceId])
    
    const deviceTagSaved = React.useMemo(() => {
        return deviceContext.saveSuccessful && (deviceContext.deviceFieldToSave?.isDeviceTag ?? false)
    }, [deviceContext.saveSuccessful, deviceContext.deviceFieldToSave?.isDeviceTag])
    
    const deviceTypeSaved = React.useMemo(() => {
        return deviceContext.saveSuccessful && (deviceContext.deviceFieldToSave?.isDeviceType ?? false)
    }, [deviceContext.saveSuccessful, deviceContext.deviceFieldToSave?.isDeviceType])
    
    const saveIdErrorMessage = React.useMemo(() => {
        const existingDeviceDto = deviceContext.existingDeviceDto;
        if (deviceContext.saveIdErrorMessage && deviceContext.saveIdErrorMessage.length > 0) {
            return deviceContext.saveIdErrorMessage;
        } else if (existingDeviceDto) {
            if (existingDeviceDto.sameIdDifTagSameLocation || existingDeviceDto.sameIdDifTagDifLocation) {
                return getLocalizedString('createDevice.samIdDifTag', "Device ID is registered to a different device. Please update the Device ID.")
            } else if (deviceContext.deviceFieldToSave?.isDeviceId) {
                return getLocalizedString('createDevice.save.deviceIdNumber.error', "Unable to save Device ID")
            }
        }
        return undefined;
    }, [deviceContext.existingDeviceDto, deviceContext.saveIdErrorMessage, deviceContext.deviceFieldToSave?.isDeviceId])
    
    const saveTagErrorMessage = React.useMemo(() => {
        const existingDeviceDto = deviceContext.existingDeviceDto;
        if (deviceContext.saveTagErrorMessage && deviceContext.saveTagErrorMessage.length > 0) {
            return deviceContext.saveTagErrorMessage;
        } else if (existingDeviceDto) {
            if (existingDeviceDto.sameTagDifIdSameLocation || existingDeviceDto.sameTagDifIdDifLocation) {
                return getLocalizedString('createDevice.sameTagDifId', "Device Serial Number is registered to a different device. Please update the Device Serial Number.")
            } else if (deviceContext.deviceFieldToSave?.isDeviceTag){
                return getLocalizedString('createDevice.save.deviceTag.error', "Unable to save Device Serial Number")
            }
        }
        return undefined;
    }, [deviceContext.existingDeviceDto, deviceContext.saveTagErrorMessage, deviceContext.deviceFieldToSave?.isDeviceTag])
    
    const deviceTypeOnChange = React.useCallback((value: number | undefined) => {
        let newFormDevice = {
            ...formDevice,
            deviceType: deviceTypeContext.deviceTypes.find(dType => dType.deviceTypeId === value)
        }
        setFormDevice(newFormDevice)
        
        if (context.currentLocation?.serialIsDevice) {
            if (formDevice.deviceType && newFormDevice.deviceType?.serialIsDevice) {
                setShowSerialIsDeviceDialog(true);
            } else {
                submitForm(newFormDevice, false, false, true)
            }
        } else {
            submitForm(newFormDevice, false, false, true);
        }
    },[context.currentLocation, formDevice, deviceTypeContext.deviceTypes, submitForm])
    
    const serialIsDeviceDialogSubmit = React.useCallback(() => {
        let newFormDevice = {
            ...formDevice,
            deviceIdNumber: computedTagIdNumber,
            deviceTag: computedTagIdNumber
        }
        setFormDevice(newFormDevice)
        submitForm(newFormDevice, true, false, true);
        setShowSerialIsDeviceDialog(false)
    }, [formDevice, submitForm, setFormDevice, computedTagIdNumber])
    
    const serialIsDeviceDialogCancel = React.useCallback(() => {
        setShowSerialIsDeviceDialog(false)
        setFormDevice({
            ...formDevice,
            deviceType: stateDevice?.deviceType
        })
    }, [setShowSerialIsDeviceDialog, formDevice, stateDevice?.deviceType])
    
    const isDeviceIdValid = React.useMemo(() => {
        return validateDeviceIdNumber(computedTagIdNumber).length > 0;
    }, [ validateDeviceIdNumber, computedTagIdNumber])
    
    const dialogTitle = React.useMemo(() => {
        if (!isDeviceIdValid) {
            return (
                <div className={'dialog-alert-title'}>
                    {getLocalizedString('createDevice.deviceType.serialIsDeviceWarning', 'Changing device type will update the Device ID to ')}
                    <span style={{color: 'var(--primary-color)'}}>{computedTagIdNumber}</span>
                </div>
            )
        } else {
            return getLocalizedString('createDevice.save.deviceType.error', 'Device type could not be changed');
        }
    }, [isDeviceIdValid, computedTagIdNumber])

    const dialogMessages = React.useMemo(() => {
        if (!isDeviceIdValid) {
            return [getLocalizedString('createDevice.serialIsDevice.explain', 'This is because ' + formDevice.deviceType?.deviceTypeName + ' uses its Serial Number as Device ID', [`${formDevice.deviceType?.deviceTypeName}`])];
        } else {
            return [getLocalizedString('createDevice.serialIsDevice.unableToUpdate', formDevice.deviceType?.deviceTypeName + ' uses its Serial Number as the Device ID, but this device\'s Serial Number does not meet the Device ID requirements,Please contact ARC support for help', [`${formDevice.deviceType?.deviceTypeName}`])]
        }
    }, [isDeviceIdValid, formDevice.deviceType?.deviceTypeName])
    
    const deviceIdTooltip = React.useMemo(() => {
        let toolTipMessage = '';
        if (context.currentBrand?.deviceNumberLengthMin) {
            if (context.currentBrand?.deviceNumberLengthMax) {
                if (context.currentBrand?.deviceNumberLengthMin === context.currentBrand?.deviceNumberLengthMax) {
                    toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${context.currentBrand?.deviceNumberLengthMin} characters`, [`${context.currentBrand?.deviceNumberLengthMax}`]);
                } else {
                    toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.minAndMax', `Device ID must be between ${context.currentBrand?.deviceNumberLengthMin} and ${context.currentBrand?.deviceNumberLengthMax} characters`, [`${context.currentBrand?.deviceNumberLengthMin}`, `${context.currentBrand?.deviceNumberLengthMax}`]);
                }
            } else {
                toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.atLeastMin', `Device ID must be at least ${context.currentBrand?.deviceNumberLengthMin} characters`, [`${context.currentBrand?.deviceNumberLengthMin}`]);
            }
        }
        const isBrandProvided = (!!context.currentBrand?.deviceNumberLengthMin) && (serialIsDeviceId || !context.currentBrand?.deviceNumberLength); 
        if (!isBrandProvided) {
            if (context.currentBrand?.deviceNumberLength) {
                if (toolTipMessage.length > 0) {
                    toolTipMessage += ' or '
                }
                toolTipMessage += getLocalizedString('createDevice.deviceIdNumber.min', `Device ID must be ${context.currentBrand?.deviceNumberLength} characters`, [`${context.currentBrand?.deviceNumberLength}`]);
            }
        }
        return toolTipMessage;
    }, [serialIsDeviceId,
                context.currentBrand?.deviceNumberLength,
                context.currentBrand?.deviceNumberLengthMax,
                context.currentBrand?.deviceNumberLengthMin])

    return  (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <DeviceDialog
                        showDialog={showSerialIsDeviceDialog}
                        dialogSubmit={serialIsDeviceDialogSubmit}
                        dialogCancel={serialIsDeviceDialogCancel}
                        title={dialogTitle}
                        messages={dialogMessages}
                        isError={isDeviceIdValid}
                    >
                    </DeviceDialog>
                    <div className={'editdevice-server-message-div'}>
                        {deviceContext.getDeviceForEditErrorMessage &&
                            <ServerMessage variant={'error'}>
                                <ErrorMessageSectionItem>
                                    {deviceContext.getDeviceForEditErrorMessage}
                                </ErrorMessageSectionItem>
                            </ServerMessage>
                        }
                    </div>
        
                    <div className={'editdevice-root-div'}>
                        <IconButton color="primary" className={'back-button'} aria-label="back" onClick={() => {
                            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                        }}>
                            <ArrowBack className={'back-button-arrow'}/>
                        </IconButton>
                        
                        <div style={{width: '100%'}}>
                            <FormTitle variant='h5' className={'o-heading--xl'} style={{paddingBottom: '15px', width: '100%'}}>
                                {(canEdit ? 'Edit' : 'View') + ' Device'}
                            </FormTitle>
                            {deviceContext.selectedDevice &&
                            <div>
                                <div className={'editdevice-status-div'}>
                                    <span>{getLocalizedString('createDevice.deviceStatus', 'This Device is ')}</span>
                                    <div style={{display: 'flex', paddingLeft: '10px', paddingRight: '10px'}}>
                                        <Tooltip placement="bottom" title={getStatusTooltip(deviceContext.selectedDevice) ?? ''}>
                                            <div>
                                                <ARCBadge 
                                                    status={deviceStatusVariant === 'success' ? 'on' : deviceStatusVariant === 'disabled' ? 'off' : 'standby'} 
                                                    label={getStatusDisplay(deviceContext.selectedDevice)} 
                                                    type={'status'}
                                                >
                                                </ARCBadge>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    {deviceContext.selectedDevice?.locationId && <span>{getLocalizedString('createDevice.deviceStatus2', ' at ') + deviceLocationName}</span>}
                                </div>
                            </div>
                            }
                            <div className={'editdevice-device-id-div'}>
                                <div className={'editdevice-device-id-column'} style={{
                                    backgroundColor: deviceIdNumberLocked ? 'transparent' : 'rgba(191, 191, 191, 0.2)',
                                    paddingLeft: deviceIdNumberLocked ? '0' : '10px',
                                    paddingBottom: deviceIdNumberLocked ? '0' : '20px',
                                }}>
                                    <div className={'editdevice-device-id-row'}>
                                        {deviceIdSaved &&
                                            <div className={'editdevice-success-text'}>
                                                {getLocalizedString('createDevice.deviceIdNumber.saved', 'New Device ID saved.')}
                                            </div>
                                        }
                                        {saveIdErrorMessage && 
                                            <div className={'editdevice-error-text'}>
                                                {saveIdErrorMessage}
                                            </div>
                                        }
                                    </div>
                                    <div className={'editdevice-device-id-row'}>
                                        <Tooltip placement={'right'} title={editAtKioskTooltip}>
                                            <div className={'editdevice-input-div'}>
                                                <ARCTextInput 
                                                    id={'addDevice-deviceId'} 
                                                    size={'md'}
                                                    required={deviceIdNumberLocked} 
                                                    disabled={true}
                                                    value={stateDevice?.deviceIdNumber ?? ''}
                                                    label={getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                                    tooltip={deviceIdTooltip}
                                                    prefix={context.currentBrand?.deviceNumberPrefix}
                                                    onChange={(value) => {
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        
                                        {deviceIdNumberLocked && canEdit && !isSamsDisabled &&
                                            <EditDeviceButton
                                                variant={'contained'}
                                                id="editdeviceid"
                                                disabled={!showDeviceIdEdit}
                                                onClick={() => {
                                                    setDeviceIdNumberLocked(false)
                                                    setFormDevice({
                                                        ...formDevice,
                                                        deviceIdNumber: ''
                                                    });
                                                }}
                                            >
                                                {getLocalizedString('edit', 'Edit')}
                                            </EditDeviceButton>
                                        }
                                    </div>
                                    {
                                        <div style={{marginTop: '16px', display: deviceIdNumberLocked ? 'none' : 'block'}}>
                                            <div className={'editdevice-device-id-row'}>
                                                <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceIdNumber}`)}>
                                                    <div className={'editdevice-input-div'}>
                                                        <ARCTextInput 
                                                            id={'addDevice-deviceId'} 
                                                            size={'md'}
                                                            required={deviceIdNumberLocked} 
                                                            disabled={deviceIdNumberLocked || !canEdit}
                                                            value={formDevice.deviceIdNumber}
                                                            label={getLocalizedString('createDevice.newDeviceIdNumber', 'New Device ID')}
                                                            prefix={context.currentBrand?.deviceNumberPrefix}
                                                            onChange={(value) => {
                                                                const error = lockDeviceIdNumber(value);
                                                                if (error) {
                                                                    const showCharacter = !isAlphanumeric(value) && (fieldErrors[`${DeviceValidationKeys.deviceIdNumber}`]?.error?.length ?? 0) === 0;
                                                                    if (showCharacter) {
                                                                        setFormDevice({
                                                                            ...formDevice,
                                                                            deviceIdNumber: value.toUpperCase(),
                                                                            deviceTag: serialIsDeviceId ? value.toUpperCase() : formDevice.deviceTag
                                                                        })
                                                                    }
                                                                    setFieldError(`${DeviceValidationKeys.deviceIdNumber}`, error);
                                                                } else {
                                                                    setFormDevice({
                                                                        ...formDevice,
                                                                        deviceIdNumber: value.toUpperCase(),
                                                                        deviceTag: serialIsDeviceId ? value.toUpperCase() : formDevice.deviceTag
                                                                    })
                                                                    clearFieldError(`${DeviceValidationKeys.deviceIdNumber}`);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </ErrorWrapper>

                                                <span className={'editdevice-edit-element'}
                                                      onClick={
                                                            () => {
                                                                setDeviceIdNumberLocked(!deviceIdNumberLocked);
                                                                setFormDevice({
                                                                    ...formDevice,
                                                                    deviceIdNumber: stateDevice?.deviceIdNumber ?? '',
                                                                    deviceTag: serialIsDeviceId ? stateDevice?.deviceTag ?? '' : formDevice.deviceTag
                                                                });
                                                                dispatch(deviceContextActions.resetSaveErrorMessages())
                                                                clearFieldError(`${DeviceValidationKeys.deviceIdNumber}`);
                                                            }
                                                        }>
                                                    {getLocalizedString('cancel', 'Cancel')}
                                                </span>
                                            </div>

                                        <div className={'editdevice-device-id-row'} style={{
                                                marginTop: '40px'
                                            }}>
                                                <SaveDeviceButton
                                                    id="editdeviceid"
                                                    variant='contained'
                                                    onClick={
                                                        () => {
                                                            submitForm({
                                                                ...formDevice,
                                                                deviceTag: serialIsDeviceId ? formDevice.deviceIdNumber : formDevice.deviceTag
                                                            }, true, false, false);
                                                        }
                                                    }
                                                    disabled={deviceIdSaveDisabled || deviceContext.saveDeviceLoading}
                                                >
                                                    {getLocalizedString('createDevice.deviceIdNumber.save', 'Save Device ID')}
                                                    {deviceContext.saveDeviceLoading &&
                                                        <CircularProgress color={'inherit'} size={calculateResponsiveSizeWithMinMax('2', '19', '8')}/>
                                                    }
                                                </SaveDeviceButton>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className={'editdevice-other-div'}>
                                <div className={'editdevice-other-title-div'}>
                                    <div className={'editdevice-other-title'}>
                                        {getLocalizedString('createDevice.otherDetails', 'Other Details')}
                                    </div>
                                </div>
                                <div className={'editdevice-device-tag-row'}>
                                    {deviceTagSaved &&
                                        <div className={'editdevice-success-text'}>
                                            {getLocalizedString('createDevice.deviceTag.saved','New Serial Number saved.')}
                                        </div>
                                    }
                                    {saveTagErrorMessage && 
                                        <div className={'editdevice-error-text'}>
                                            {saveTagErrorMessage}
                                        </div>
                                    }
                                </div>
                                <div className={'editdevice-device-tag-row'}>
                                    <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceTag}`)}>
                                        <Tooltip placement={'right'} title={editAtKioskTooltip}>
                                            <div className={'editdevice-input-div'}>
                                                <ARCTextInput 
                                                    id={'addDevice-deviceTag'} 
                                                    size={'md'}
                                                    required={true} 
                                                    value={formDevice.deviceTag}
                                                    label={getLocalizedString('createDevice.deviceTag', 'Device Serial Number')}
                                                    tooltip={getLocalizedString('createDevice.deviceTag.tooltip', 'This is the unique identifier associated with the device.')}
                                                    disabled={deviceTagLocked || !canEdit || isSamsDisabled}
                                                    onChange={(event) => {
                                                        const error = validateDeviceTag(event);
                                                        if (error) {
                                                            const showCharacter = !isAlphanumeric(event) && (fieldErrors[`${DeviceValidationKeys.deviceTag}`]?.error?.length ?? 0) === 0;
                                                            if (showCharacter) {
                                                                setFormDevice({
                                                                    ...formDevice,
                                                                    deviceTag: event
                                                                });
                                                            }
                                                            setFieldError(`${DeviceValidationKeys.deviceTag}`, error);
                                                        } else {
                                                            setFormDevice({
                                                                ...formDevice,
                                                                deviceTag: event
                                                            });
                                                            clearFieldError(`${DeviceValidationKeys.deviceTag}`);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </ErrorWrapper>
                                    {canEdit && !serialIsDeviceId && !isSamsDisabled && 
                                        <button 
                                            className={'editdevice-edit-element'}
                                            type={'button'}
                                            onClick={
                                                () => {
                                                    setDeviceTagLocked(!deviceTagLocked);
                                                    !deviceTagLocked && 
                                                        setFormDevice({
                                                            ...formDevice,
                                                            deviceTag: stateDevice?.deviceTag ?? ''
                                                        })
                                                    dispatch(deviceContextActions.resetSaveErrorMessages())
                                                    clearFieldError(`${DeviceValidationKeys.deviceTag}`);
                                                }
                                            }
                                            disabled={!showDeviceTagEdit}>
                                            {deviceTagLocked ? getLocalizedString('edit', 'Edit') : getLocalizedString('cancel', 'Cancel')}
                                        </button>
                                    }
                                </div>
                                {!deviceTagLocked &&
                                    <div className={'editdevice-device-id-row'} style={{
                                        marginBottom: '10px'
                                    }}>
                                        <SaveDeviceButton
                                            id="editdeviceid"
                                            variant='contained'
                                            onClick={
                                                () => {
                                                    submitForm(formDevice,false, true, false);
                                                }
                                            }
                                            disabled={deviceTagSaveDisabled || deviceContext.saveDeviceLoading}
                                        >
                                            {getLocalizedString('createDevice.deviceTag.save', 'Save Serial Number')}
                                            {deviceContext.saveDeviceLoading &&
                                                <CircularProgress color={'inherit'} size={calculateResponsiveSizeWithMinMax('2', '19', '8')}/>
                                            }
                                        </SaveDeviceButton>
                                    </div>
                                }
                                {(deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) &&
                                    <>
                                        <div className={'editdevice-device-tag-row'}>
                                            {deviceTypeSaved &&
                                                <div className={'editdevice-success-text'}>
                                                    {getLocalizedString('createDevice.deviceType.saved', 'New Device Type saved.')}
                                                </div>
                                            }
                                            {deviceContext.saveTypeErrorMessage && 
                                                <div className={'editdevice-error-text'}>
                                                    {deviceContext.saveTypeErrorMessage}
                                                </div>
                                            }
                                        </div>
                                        <div className={'editdevice-device-type-title-div'}>
                                            <div className={'editdevice-device-type-title'}>
                                                {getLocalizedString('createDevice.deviceType', 'Device Type')}
                                                <span className={'editdevice-red-star'}>
                                                    {'*'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={'editdevice-device-type-row'}>
                                            <div className={'editdevice-input-div'}>
                                                <ARCSelectInput
                                                    id={'create-device-select-device-type-input'}
                                                    size={'md'}
                                                    className={'createdevice-device-type-select'}
                                                    value={formDevice.deviceType?.deviceTypeId}
                                                    options={DeviceTypeOptions}
                                                    disabled={deviceTypeLocked || !canEdit}
                                                    onChange={(option) => {
                                                        deviceTypeOnChange(option.value)
                                                    }}
                                                />
                                            </div>
                                            {canEdit &&
                                                <button 
                                                    className={'editdevice-edit-element'}
                                                    style={{marginBottom: '2px'}}
                                                    type={'button'}
                                                    onClick={() => {
                                                            setDeviceTypeLocked(!deviceTypeLocked)
                                                            dispatch(deviceContextActions.resetSaveErrorMessages())
                                                    }}
                                                    disabled={!showDeviceTypeEdit}>
                                                    {deviceTypeLocked ? getLocalizedString('edit', 'Edit') : getLocalizedString('cancel', 'Cancel')}
                                                </button>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            <br/>
                            <div className={'editdevice-more-div'}>
                                {canEdit ?
                                    <>
                                        <div className={'editdevice-more-title-div'}>
                                            <div className={'editdevice-more-title'}>
                                                {getLocalizedString('createDevice.moreActions', 'More Actions')}
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', flexWrap: 'wrap', flexDirection: 'row', width: '50vw'}}>
                                            <div style={{display: 'flex', minWidth: '260px', justifyContent: 'flex-start', marginTop: '20px', marginRight: '10px'}}>
                                                <ARCButton
                                                    fill={'outlined'}
                                                    variant={'primary'}
                                                    size={'md'}
                                                    label={getLocalizedString('createDevice.reports', 'View Device Insights')}
                                                    onClick={() => navigate(`/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${DEVICE_DASHBOARD.lookerId}/deviceId=${formDevice.deviceId ?? ''}`)}
                                                    icon={'graph'}
                                                >
                                                </ARCButton>
                                            </div>
                                            {isInactive(formDevice) ?
                                                <div style={{display: 'flex', minWidth: '260px', justifyContent: 'flex-start', marginTop: '15px'}}>
                                                    <ARCButton
                                                        fill={'outlined'}
                                                        variant={'primary'}
                                                        size={'md'}
                                                        label={getLocalizedString('createDevice.reactivate', 'Reactivate Device')}
                                                        onClick={() => navigate(`/devices/reactivate/locationId=${context.currentLocation?.locationId}/deviceId=${formDevice.deviceId}`, {state: {editingFromDeviceId: formDevice.deviceId}})}
                                                        icon={'power'}
                                                    >
                                                    </ARCButton>
                                                </div>
                                                :
                                                <div style={{display: 'flex', minWidth: '260px', justifyContent: 'flex-start', marginTop: '15px'}}>
                                                    <ARCButton
                                                        fill={'outlined'}
                                                        variant={'secondary'}
                                                        size={'md'}
                                                        label={getLocalizedString('createDevice.deactivate', 'Deactivate Device')}
                                                        onClick={() => navigate(`/devices/deactivate/locationId=${context.currentLocation?.locationId}/deviceId=${formDevice.deviceId}`, {state: {editingFromDeviceId: formDevice.deviceId}})}
                                                        icon={'power'}
                                                    >
                                                    </ARCButton>
                                                </div>
                                            }
                                        </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                            
                            {/*{(formDevice.deviceId && formDevice.deviceHistoryList && formDevice.deviceHistoryList?.length > 0) &&*/}
                            {/*<div style={{width: '100%'}}>*/}
                            {/*    <Spacer/>*/}
                            {/*    <ResponsiveInputLabelThinSm style={{maxWidth: '150px', minWidth:'unset'}}>{'Device Update Log'}</ResponsiveInputLabelThinSm>*/}
                            {/*    <Table>*/}
                            {/*        <TableHead>*/}
                            {/*            <TableRow>*/}
                            {/*                <UpdateLogHeaderCell>{'Date'}</UpdateLogHeaderCell>*/}
                            {/*                <UpdateLogHeaderCell>{'Reason'}</UpdateLogHeaderCell>*/}
                            {/*                <UpdateLogHeaderCell>{'Updated By'}</UpdateLogHeaderCell>*/}
                            {/*            </TableRow>*/}
                            {/*        </TableHead>*/}
                            
                            {/*        <TableBody>*/}
                            {/*            {formDevice.deviceHistoryList?.map((deviceHistory, idx, {length}) => {*/}
                            {/*                    return (*/}
                            {/*                        <TableRow>*/}
                            {/*                            <UpdateLogCell>{deviceHistory.changeDateTime}</UpdateLogCell>*/}
                            {/*                            <UpdateLogCell>{(!deviceHistory.reason && idx === length - 1) ? 'Record Created' : deviceHistory.reason}</UpdateLogCell>*/}
                            {/*                            <UpdateLogCell>{deviceHistory.changeUserName}</UpdateLogCell>*/}
                            {/*                        </TableRow>*/}
                            {/*                    )*/}
                            {/*                }*/}
                            {/*            )}*/}
                            {/*        </TableBody>*/}
                            {/*    </Table>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </div>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}

const EditDeviceButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '3px 20px 3px 20px !important',
    minWidth: '0px !important',
    marginLeft: '20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

const SaveDeviceButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '10px 20px 10px 20px !important',
    minWidth: '0px !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

