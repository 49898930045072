import * as React from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
// import {LocaleSelect} from "../LocaleSelect";

type NavLink = {
    title: string;
    url: string;
    domain: string;
    hidden?: boolean;
};

type Section = {
    title: NavLink;
    navLinks: NavLink[];
    hidden?: boolean;
};

type ARCMenuProps = {
    sections: Section[]
}

export const ARCMenu : React.FC<ARCMenuProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <button className="o-nav__button" onClick={handleToggleMenu}>
                <div className="u-margin-unset">
                    ☰
                </div>
            </button>
            <div className={`o-nav__menu ${isOpen ? 'open' : ''}`}>
                {/*<div className={'o-nav__section'} style={{width: '120px'}}>*/}
                {/*    <LocaleSelect/>*/}
                {/*</div>*/}

                <button className="o-nav__button o-nav__button--close" onClick={handleToggleMenu}>
                    ✕
                </button>

                {props.sections.map((section, index) => {
                    return (
                        <div key={index}>
                            {!section.hidden &&
                            <div className="o-nav__section">
                                <div
                                    className={`o-nav__section__title ${location.pathname.includes(section.title.domain) ? 'active' : ''}`}
                                    onClick={
                                        () => {
                                            navigate(section.title.url);
                                            handleToggleMenu();
                                        }
                                    }>
                                    {section.title.title}
                                </div>

                                {section.navLinks.map((navLink, idx) => (
                                    <div key={idx}>
                                        {!navLink.hidden &&
                                        <div className="o-nav__section__child" key={idx}>
                                            <div
                                                className={`o-nav__section__child__label ${location.pathname.includes(navLink.domain) ? 'active' : ''}`}
                                                onClick={
                                                    () => {
                                                        navigate(navLink.url);
                                                        handleToggleMenu();
                                                    }
                                                }
                                            >
                                                {navLink.title}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                    )
                })}
            </div>
        </>
    );
}