import * as React from "react";
import "./ARCSecondaryNav.scss"
import {Link} from "react-router-dom";

type ARCSecondaryNavProps = {
    id: string;
    options: SecondaryNavOption[];
    className?: string;
    style?: React.CSSProperties;
}

type SecondaryNavOption = {
    isSelected: boolean;
    onClick: () => void;
    href: string;
    label: string;
    disabled?: boolean;
}

export const ARCSecondaryNav : React.FC<ARCSecondaryNavProps> = (props) => {
    return (
        <nav className={'c-nav-secondary'} id={props.id}>
            <ul className={'c-nav-secondary__menu'}>
                {props.options.map((option, key) => {
                    return (
                        <li>
                            <Link
                                key={key}
                                className={'c-nav-secondary__text' + (option.disabled ? ' disabled' : '') + (option.isSelected ? ' active' : '')}
                                onClick={(event) => {
                                    if (option.href === '') {
                                        event.preventDefault();
                                    }
                                    option.onClick();
                                }}
                                to={option.href}
                            >
                                {option.label}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
};
