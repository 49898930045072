import * as React from 'react';
import {selectLocationThunk} from "../app-context/thunks/SelectLocationThunk";
import {AllLocationsDto, LocationDto} from "../../dtos/Location";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextSelector} from "../app-context/appContextSlice";
import {employeeContextActions} from "../employees/employeeSlice";
import {deviceContextActions} from "../devices/deviceSlice";
import {devicePickupPermissionActions} from "../devices/devicePickupPermissionSlice";
import {ARCSearchSelect} from "./design-system/search-select/ARCSearchSelect";

type LocationSelectProps = {
    showAllLocations: boolean,
    placeHolder?: string,
    removeSerialIsDevice?: boolean
}

export const LocationSelect: React.ComponentType<LocationSelectProps> = ({showAllLocations, placeHolder, removeSerialIsDevice}) => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();

    const authDto = context.authorizationDto;
    let sortedLocations;
    // dispatch(selectLocationThunk(parseInt(locationId)));

    if(authDto?.authorizedLocations && context.currentBrand) {
        let locationsForBrand = authDto?.authorizedLocations.filter(l => l.brand.brandId === context.currentBrand?.brandId);
        if (removeSerialIsDevice) {
            locationsForBrand = locationsForBrand?.filter(l => !l.serialIsDevice);
        }
        const locations: Omit<LocationDto, 'brand'>[] = [...locationsForBrand];

        sortedLocations = (locations?.sort((l1, l2) => l1.locationName.localeCompare(l2.locationName)));
        
        if (showAllLocations) {
            sortedLocations.unshift(AllLocationsDto);
        }
    }

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();

    const navigateToNewLocation = React.useCallback((locationId: number) => {
        let path = pathname;
        Object.entries(params).forEach(([paramName, paramValue]) => {
            if (paramName === 'locationId' && paramValue) {
                path = path.replace(paramValue, `${locationId}`);
            }
        });

        navigate(path);
    }, [navigate, params, pathname]);

    const onLocationSelect = React.useCallback((locationId: string) => {
        if(parseInt(locationId) !== context.currentLocation?.locationId) {
            dispatch(selectLocationThunk(parseInt(locationId)));
            dispatch(employeeContextActions.clearEmployeeFilter());
            dispatch(deviceContextActions.clearDeviceFilter());
            dispatch(devicePickupPermissionActions.resetPermissionsList());
            navigateToNewLocation(parseInt(locationId));
        }
    }, [context.currentLocation?.locationId, dispatch, navigateToNewLocation]);

    const currentLocation = React.useMemo(() => {
        return context.currentLocation?.locationId.toString();
    }, [context.currentLocation?.locationId]);

    return (
        <ARCSearchSelect
            placeHolder={placeHolder}
            disabled={context.authorizationDto?.authorizedLocations.length === 1}
            onSelect={onLocationSelect}
            options={[
                ...(sortedLocations ?? []).map(loc => {
                    return {
                        name: loc.locationName, value: loc.locationId.toString()
                    }
                })
            ]}
            value={currentLocation}
            id={`location-search-select-input`}
        />
    )
}
